@tailwind base;
@tailwind components;
@tailwind utilities;

@layer layer {
  html,
  body {
    @apply font-Prompt;
    font-size: 18px;
    font-weight: 400;
  }
  small,
  .small {
    font-size: 14px;
  }
}

@layer components {
  .btn {
    font-size: 18px;
  }
  .btn span {
    display: inline-flex;
    gap: 0.5rem;
  }
}

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

body {
  font-family: 'Prompt', sans-serif;
  background-color: rgb(255, 255, 255);
}

div#__next {
  transition: all 0.25s ease-in-out;
}

.btn-primary,
.badge-primary {
  color: #fff;
}

a,
.btn-link {
  text-decoration: none;
}

.saboxplugin-wrap {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 0px 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid #eee;
  width: 100%;
  clear: both;
  display: none;
  overflow: hidden;
  word-wrap: break-word;
  position: relative;
}
.saboxplugin-wrap .saboxplugin-gravatar {
  float: left;
  padding: 0 20px 20px 20px;
}
.saboxplugin-wrap .saboxplugin-gravatar img {
  max-width: 100px;
  height: auto;
  border-radius: 0;
}
.saboxplugin-wrap .saboxplugin-authorname {
  font-size: 18px;
  line-height: 25px;
  margin: 20px 0 0 20px;
  display: block;
}
.saboxplugin-wrap .saboxplugin-desc {
  display: block;
  margin: 5px 20px;
}
.saboxplugin-wrap .saboxplugin-desc p,
.saboxplugin-wrap .saboxplugin-desc {
  font-size: 14px !important;
  line-height: 21px !important;
}
.saboxplugin-wrap .saboxplugin-web {
  margin: 0 20px 15px;
  text-align: left;
  font-size: 14px;
}
.saboxplugin-wrap .saboxplugin-socials {
  position: relative;
  display: block;
  padding: 5px;
}
.saboxplugin-wrap .saboxplugin-socials .saboxplugin-icon-grey {
  text-decoration: inherit;
  box-shadow: none;
  position: relative;
  display: -moz-inline-stack;
  display: inline-block;
  vertical-align: middle;
  zoom: 1;
  margin: 10px 5px;
  @apply text-primary fill-primary;
}
.saboxplugin-wrap .saboxplugin-socials a svg {
  width: 18px;
  height: 18px;
}

.boxes-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}

.filter-list-item {
  cursor: pointer;
  display: inline-block;
  padding: 5px 15px;
  margin-bottom: 10px;
  background-color: #F8F8F8;
  background-image: linear-gradient( hsla(0, 0%, 0%, 0), hsla(0, 0%, 0%, 0.1) );
  border: 1px solid #CCC;
}
.filter-list-item.active {
  background-color: #28a92b;
}

.divide-x > * + * {
  border-right-width: 0px;
  border-left-width: 1px;
}
.divide-y > * + * {
  border-top-width: 1px;
  border-bottom-width: 0px;
}
.divide-slate-200 > * + * {
  border-color: rgb(226 232 240);
}
.divide-solid > * + * {
  border-style: solid;
}

.wp-block-image .aligncenter{
  display: flex;
  justify-content: center;
}

.top_screen {
  height: calc(100vh);
  position: relative;
  width: 100%;
  overflow: hidden;
}
.fit-contetn {
  position: relative;
  width: 100%;
  display: block;
}
.splash_content {
  position: absolute;
  width: calc(100% - 72px);
}
@media (min-width: 745px) {
  .splash_content {
    bottom: 36px;
    left: 36px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.splash_title {
  grid-area: title / title / title / title;
  display: grid;
  row-gap: 20px;
  justify-items: start;
}
.splash_description {
    grid-area: description / description / description / description;
    display: grid;
    justify-items: start;
}
.animate_text_delay {
  --delay: 2.1s;
  font-size: 4rem;
  line-height: 1;
}
.animate_text_delay.txtloading {
  --delay: 2.1s;
  font-size: 1.5rem;
  line-height: 1;
}
.animate_text {
    font-size: inherit;
    font-weight: bold;
    line-height: inherit;
    letter-spacing: inherit;
    padding: 0.1em 0.12em;
}
.animate_text_after {
    position: absolute;
    top: 0px;
    left: 0px;
    color: var(--color-black);
    background: var(--color-white);
    clip-path: polygon(0px 0px, 0px 0px, 0px 100%, 0px 100%);
    animation: textClippath 0.4s cubic-bezier(0.76,-0.02,0.2,0.98) forwards;
}
.animate_text_before {
    position: relative;
    color: inherit;
    background: none;
    clip-path: polygon(0px 0px, 0px 0px, 0px 100%, 0px 100%);
    animation: textClippath 0.6s cubic-bezier(0.76,-0.02,0.2,0.98) forwards;
}
.splash_text-content {
  position: relative;
  width: fit-content;
  display: block;
}
.isnHcT {
    --delay: 2.1s;
    font-size:1.8rem;
    line-height: 1;
    
}
@keyframes textClippath {
  100% {
    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%);
  }
}

.move {
  animation: 2.5s ease 0s infinite normal none running moveupdown;
}

@keyframes moveupdown {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-5px);
  }
}

.btn-bubbly:focus {
  outline: 0;
}
.btn-bubbly:before, .btn-bubbly:after {
  position: absolute;
  content: '';
  display: block;
  width: 140%;
  height: 100%;
  left: -20%;
  z-index: -1000;
  transition: all ease-in-out 0.5s;
  background-repeat: no-repeat;
}
.btn-bubbly:before{
  display: none;
  top: -75%;
  background-image:  
    radial-gradient(circle, #1990ff 20%, transparent 20%),
  radial-gradient(circle,  transparent 20%, #1990ff 20%, transparent 30%),
  radial-gradient(circle, #1990ff 20%, transparent 20%), 
  radial-gradient(circle, #1990ff 20%, transparent 20%),
  radial-gradient(circle,  transparent 10%, #1990ff 15%, transparent 20%),
  radial-gradient(circle, #1990ff 20%, transparent 20%),
  radial-gradient(circle, #1990ff 20%, transparent 20%),
  radial-gradient(circle, #1990ff 20%, transparent 20%),
  radial-gradient(circle, #1990ff 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
}
.btn-bubbly:after{
  display: none;
  bottom: -75%;
  background-image:  
  radial-gradient(circle, #1990ff 20%, transparent 20%), 
  radial-gradient(circle, #1990ff 20%, transparent 20%),
  radial-gradient(circle,  transparent 10%, #1990ff 15%, transparent 20%),
  radial-gradient(circle, #1990ff 20%, transparent 20%),
  radial-gradient(circle, #1990ff 20%, transparent 20%),
  radial-gradient(circle, #1990ff 20%, transparent 20%),
  radial-gradient(circle, #1990ff 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}

.btn-bubbly:active{
  transform: scale(0.9);
  background-color: darken(#1990ff, 5%);
  box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
}

.btn-bubbly.animate:before{
  display: block;
  animation: topBubbles ease-in-out 0.75s forwards;
}
.btn-bubbly.animate:after{
  display: block;
  animation: bottomBubbles ease-in-out 0.75s forwards;
}


@keyframes topBubbles {
  0%{
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
    50% {
      background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;}
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}

@keyframes bottomBubbles {
  0%{
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;}
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}

.ais-Hits-list.grid {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  display: grid;
}
@media (min-width: 768px) {
  .ais-Hits-list.grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
  }
}

.ais-Hits-item {
  padding: 0;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 0;
  margin-right: 0;
  border: 0;
  box-shadow: none;
  width: 100%;
}
.ais-Hits-item.featured-post {
  padding: 0;
  margin-top: 0;
  margin-left: 0;
  margin-bottom: 0;
  margin-right: 0;
  border: 0;
  box-shadow: none;
  width: 100%;
}
